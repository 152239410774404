html, body {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  overflow-y: auto;
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow-y: auto;
}
